import React, { useState, useEffect } from 'react';
import TenantForm from './TenantForm';
import TenantTable from './TenantTable';
import DeleteModal from './DeleteModal';
import * as TenantTypes from '../types/Tenant';
import { createTenantService } from '../services/tenantService';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import { useMsal } from "@azure/msal-react";

const TenantManager: React.FC = () => {
  const authenticatedFetch = useAuthenticatedFetch();
  const tenantService = createTenantService(authenticatedFetch);
  const { instance } = useMsal();
  
  const [tenants, setTenants] = useState<TenantTypes.Tenant[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState<TenantTypes.Tenant | null>(null);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    loadTenants();
  }, []);

  const loadTenants = async () => {
    setIsLoading(true);  // Set loading to true when starting to fetch
    try {
      console.log("Fetching tenants...");
      console.log("tenantService.fetchTenants:", tenantService.fetchTenants);
      const fetchedTenants = await tenantService.fetchTenants();
      console.log("Fetched tenants:", fetchedTenants);
      setTenants(fetchedTenants);
    } catch (err) {
      console.error("Error fetching tenants:", err);
      setError('Failed to load tenants. Please try again later.');
    } finally {
      setIsLoading(false);  // Set loading to false when fetch completes (success or failure)
    }
  };

  const handleAddTenant = async (newTenant: TenantTypes.TenantFormData): Promise<void> => {
    setError(null);
    try {
      const addedTenant = await tenantService.addTenant(newTenant);
      setTenants([addedTenant, ...tenants]);
    } catch (err: any) {
      console.log('Error response:', err);
      // Set the error message from the response
      if (err?.errors?.[0]?.msg) {
          setError(err.errors[0].msg);
      } else {
          setError('Failed to create tenant. Please try again.');
      }
      throw err
    }
  };

  const handleDeleteTenant = async (): Promise<void> => {
    if (tenantToDelete) {
      try {
        await tenantService.deleteTenant(tenantToDelete.Id);
        setTenants((prevTenants) => 
          prevTenants.filter((tenant) => tenant.Id !== tenantToDelete.Id)
        );
        setShowDeleteModal(false);
      } catch (err) {
        setError('Failed to delete tenant. Please try again.');
      }
    }
  };

  const handleCheckStatus = async (id: string): Promise<void> => {
    try {
      const updatedTenant = await tenantService.checkTenantStatus(id);
      console.log("Updated tenant:", updatedTenant);
      setTenants((prevTenants) =>
        prevTenants.map((tenant) =>
          tenant.Id === id ? { ...tenant, ...updatedTenant } : tenant
        )
      );
    } catch (err) {
      setError('Failed to check tenant status. Please try again.');
    }
  };

  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  return (
    <div className="tenant-manager">
      <div className="content-wrapper">
      <h1>Demo Tenant Manager</h1>
        <div className="form-container">
          <h2>Create Tenant</h2>
          {error && <div className="error">{error}</div>}
          <TenantForm onSubmit={handleAddTenant} />
        </div>
        <TenantTable
          tenants={tenants}
          onCheckStatus={handleCheckStatus}
          onDeleteTenant={(tenant) => {
            setTenantToDelete(tenant);
            setShowDeleteModal(true);
          }}
          isLoading={isLoading}
        />
      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteTenant}
        tenantName={tenantToDelete?.tenantName || ''}
      />
    </div>
  );
};

export default TenantManager;