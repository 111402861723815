import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import TenantManager from './components/TenantManager'
import AdminPage from './components/AdminPage';
import otlogo from './otlogo.svg'; 
import HelpModal from './components/HelpModal';

const App: React.FC = () => {
  const { instance } = useMsal();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    const initializeApp = async () => {
      setIsLoading(true); // Set loading to true at the start
      await instance.initialize();
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }
      setIsInitialized(true);
      setIsLoading(false); // Set loading to false once initialization is complete
    };

    initializeApp();
  }, [instance]);

  if (isLoading) {
    return <div>Loading...</div>; // Render loading indicator
  }

  const handleLogin = async () => {
    try {
      const result = await instance.loginPopup(loginRequest);
      if (result) {
        instance.setActiveAccount(result.account);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  const hasAdminRole = () => {
    const account = instance.getActiveAccount();
    if (!account) return false;
    
    const idTokenClaims = account.idTokenClaims as { roles?: string[] };
    const userRoles = idTokenClaims?.roles || [];
    return userRoles.includes('DemoTenantMgmtAdmin');
  };

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <AuthenticatedTemplate>
          <nav>
            <img src={otlogo} alt="OT Logo" className="ot-logo" />
            <div className="nav-links">
              <Link to="/tenants">Tenant Manager</Link>
              {hasAdminRole() && <Link to="/admin">Admin</Link>}
              <a href="#" onClick={(e) => {
                e.preventDefault();
                setShowHelpModal(true);
              }}>Help</a>
            </div>
            <div className="nav-auth">
              <a href="#" onClick={handleLogout} className="sign-out-link">Sign Out</a>
            </div>
          </nav>
          <Routes>
            <Route path="/tenants" element={<TenantManager />} />
            <Route path="/admin" element={hasAdminRole() ? <AdminPage /> : <Navigate to="/tenants" replace />} />
            <Route path="*" element={<Navigate to="/tenants" replace />} />
          </Routes>
          <HelpModal 
            isOpen={showHelpModal}
            onClose={() => setShowHelpModal(false)}
          />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="login-container">
            <h1>OneTrust Demo Tenant Manager</h1>
            <p className="login-instruction">Please sign in to access the demo tenant management system.</p>
            <button className="btn login-btn" onClick={handleLogin}>Sign In</button>
          </div>
        </UnauthenticatedTemplate>
      </div>
    </Router>
  );
}

export default App;
