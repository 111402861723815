import { Tenant, TenantFormData } from '../types/Tenant';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005';

export const createTenantService = (authenticatedFetch: (url: string, options?: RequestInit) => Promise<Response>) => ({
  fetchTenants: async (): Promise<Tenant[]> => {
    const response = await authenticatedFetch(`${API_URL}/tenants/getTenants`);
    if (!response.ok) throw new Error('Failed to fetch tenants');
    return response.json();
  },

  addTenant: async (tenant: TenantFormData): Promise<Tenant> => {
    const response = await authenticatedFetch(`${API_URL}/tenants`, {
      method: 'POST',
      body: JSON.stringify(tenant),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw errorData; // Throw the actual error response
    }
    return response.json();
  },

  deleteTenant: async (id: string): Promise<void> => {
    const response = await authenticatedFetch(`${API_URL}/tenants/${id}`, { method: 'DELETE' });
    if (!response.ok) throw new Error('Failed to delete tenant');
  },

  checkTenantStatus: async (id: string): Promise<Tenant> => {
    const response = await authenticatedFetch(`${API_URL}/tenants/create/status/${id}`);
    if (!response.ok) throw new Error('Failed to check tenant status');
    return response.json();
  },
});